import axios from '@axios'
import interceptor from '@/store/interceptor'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    deleteLeads(ctx, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/form-inputs/delete-all', formData)
          .then(response => { return resolve(response) })
          .catch(error => { return interceptor(reject, error) })
      })
    },
    getAllLandingPages(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/landing-pages/get-all', { params: queryParams })
          .then(response => { return resolve(response) })
          .catch(error => { return interceptor(reject, error) })
      })
    },
    getAllLeadStatuses(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/lead-statuses/get-all', { params: queryParams })
          .then(response => { return resolve(response) })
          .catch(error => { return interceptor(reject, error) })
      })
    },
    getWithoutAllLeadStatuses(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/lead-statuses/get-without-all', { params: queryParams })
          .then(response => { return resolve(response) })
          .catch(error => { return interceptor(reject, error) })
      })
    },
    getAllMarketingChannels(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/forms-inputs/get-all-marketing-channel', { params: queryParams })
          .then(response => { return resolve(response) })
          .catch(error => { return interceptor(reject, error) })
      })
    },
    getAllMarketingChannelsByLandingPage(ctx, { uuid }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/forms-inputs/get-all-marketing-channel/${uuid}`)
          .then(response => { return resolve(response) })
          .catch(error => { return interceptor(reject, error) })
      })
    },
    getSalespersons(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/user/salesperson-lists', { params: queryParams })
          .then(response => { return resolve(response) })
          .catch(error => { return interceptor(reject, error) })
      })
    },
    getMarketingConversionRateByLandingPage(ctx, { uuid }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/forms-inputs/get-conversion-rate-marketing-channel/${uuid}`)
          .then(response => { return resolve(response) })
          .catch(error => { return interceptor(reject, error) })
      })
    },
    getCountLeadStatuses(ctx, { uuid }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/landing-pages/count-lead-status/${uuid}`)
          .then(response => { return resolve(response) })
          .catch(error => { return interceptor(reject, error) })
      })
    },
    getSalespersonByLandingPage(ctx, { uuid }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/salesperson-history/by-landing-page/${uuid}`)
          .then(response => { return resolve(response) })
          .catch(error => { return interceptor(reject, error) })
      })
    },
    allSalespersonAssignedByLandingPage(ctx, { uuid }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/user/select2-assigned-salesperson/${uuid}`)
          .then(response => { return resolve(response) })
          .catch(error => { return interceptor(reject, error) })
      })
    },
    allSalespersonAssignedByLandingPageHaveLeads(ctx, { uuid }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/user/select2-salesperson-have-lead/${uuid}`)
          .then(response => { return resolve(response) })
          .catch(error => { return interceptor(reject, error) })
      })
    },
    getDetailFormInput(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/form-inputs/${id}`)
          .then(response => { return resolve(response) })
          .catch(error => { return reject(error) })
      })
    },
    getLeadDetailLog(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/form-inputs/get-detail-log/${id}`)
          .then(response => { return resolve(response) })
          .catch(error => { return interceptor(reject, error) })
      })
    },
    getLeadFiles(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/salesperson-history/lead-file/${id}`)
          .then(response => { return resolve(response) })
          .catch(error => { return interceptor(reject, error) })
      })
    },
    getFormResults(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/form-inputs/get-by-landing-page/${payload.uuid}`, { params: payload })
          .then(response => { return resolve(response) })
          .catch(error => { return interceptor(reject, error) })
      })
    },
    getTopSalespersonDeal(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/salesperson-history/top-deal-leads/${payload.uuid}`, { params: payload })
          .then(response => { return resolve(response) })
          .catch(error => { return interceptor(reject, error) })
      })
    },
    addSalespersonList(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/salesperson-list/store-list', userData)
          .then(response => { return resolve(response) })
          .catch(error => { return interceptor(reject, error) })
      })
    },
    assignSalesperson(ctx, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/salesperson-history', formData)
          .then(response => { return resolve(response) })
          .catch(error => { return interceptor(reject, error) })
      })
    },
    uploadLeadFile(ctx, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/salesperson-history/lead-file', formData)
          .then(response => { return resolve(response) })
          .catch(error => { return interceptor(reject, error) })
      })
    },
    deleteLeadFile(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/salesperson-history/lead-file/delete/${payload.id}`)
          .then(response => { return resolve(response) })
          .catch(error => { return interceptor(reject, error) })
      })
    },
    downloadLeadFile(ctx, { id, fileName, extension }) {
      const options = {}
      options.responseType = 'blob'
      return new Promise((resolve, reject) => {
        axios
          .get(`/salesperson-history/lead-file/download/${id}`, options)
          .then(response => {
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(
              new Blob([response.data]),
            )
            link.setAttribute('download', `${fileName}.${extension}`)
            document.body.appendChild(link)
            link.click()
            return resolve(response)
          })
          .catch(error => { return interceptor(reject, error) })
      })
    },
    updateLeadReshuffleStatus(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/form-inputs/change-reshuffle-status/${payload.form_input_id}`, payload.data)
          .then(response => { return resolve(response) })
          .catch(error => { return interceptor(reject, error) })
      })
    },
    updateHotlead(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .patch(`/form-inputs/change-hotlead/${payload.form_input_id}`, payload.data)
          .then(response => { return resolve(response) })
          .catch(error => { return interceptor(reject, error) })
      })
    },
    updateLeadStatusDetail(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/form-inputs/change-status-detail/${payload.form_input_id}`, payload.data)
          .then(response => { return resolve(response) })
          .catch(error => { return interceptor(reject, error) })
      })
    },
    actionImport(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/form-inputs/import', payload)
          .then(response => {
            return resolve(response)
          })
          .catch(error => { return interceptor(reject, error) })
      })
    },
    actionParseCSV(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/form-inputs/parse-csv', payload)
          .then(response => {
            return resolve(response)
          })
          .catch(error => { return interceptor(reject, error) })
      })
    },
    actionExport(ctx, payload) {
      const options = {}
      options.responseType = 'blob'
      return new Promise((resolve, reject) => {
        axios
          .post('/form-inputs/export', payload, options)
          .then(response => {
            const link = document.createElement('a')
            link.href = window.URL.createObjectURL(
              new Blob([response.data]),
            )
            link.setAttribute('download', 'Entry Leads.csv')
            document.body.appendChild(link)
            link.click()
            return resolve(response)
          })
          .catch(error => { return interceptor(reject, error) })
      })
    },
  },
}
