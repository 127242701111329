import useJwt from '@/auth/jwt/useJwt'
import router from '@/router'

export default function interceptor(reject, error) {
  if (error.response?.status === 401){
    if (router.currentRoute.name !== 'landing-pages-display-by-company-slug') {
      // removed token from localStorage.
      localStorage.removeItem(useJwt.jwtConfig.storageTokenKeyName)
      localStorage.removeItem(useJwt.jwtConfig.storageRefreshTokenKeyName)
      localStorage.removeItem('userData')
      return router.push({ name: 'auth-login' })
    }
  }
  return reject(error)
}
