export default [
  {
    path: '/access-control',
    name: 'access-control',
    component: () => { return import('@/views/extensions/acl/AccessControl.vue') },
    meta: {
      resource: 'ACL',
      action: 'read',
    },
  },
]
