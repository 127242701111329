import axios from '@axios'
import interceptor from '@/store/interceptor'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getTopLandingPage(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/landing-pages/get-top-landing-page', { params: queryParams })
          .then(response => { return resolve(response) })
          .catch(error => { return interceptor(reject, error) })
      })
    },
    getTopSubmissionMarketingChannel(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/forms-inputs/get-top-marketing-channel', { params: queryParams })
          .then(response => { return resolve(response) })
          .catch(error => { return interceptor(reject, error) })
      })
    },
    getTopSalespersonLeadDetail(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/salesperson-list/lead-detail', { params: queryParams })
          .then(response => { return resolve(response) })
          .catch(error => { return interceptor(reject, error) })
      })
    },
    getDealPerYearChart(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/landing-pages/chart-data/deal-per-year', { params: queryParams })
          .then(response => { return resolve(response) })
          .catch(error => { return interceptor(reject, error) })
      })
    },
    getTopActiveLandingPage(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/landing-pages/get-top-active', { params: queryParams })
          .then(response => { return resolve(response) })
          .catch(error => { return interceptor(reject, error) })
      })
    },
    getTotalMonthlySubmission(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .get('/landing-pages/get-monthly-submission', { params })
          .then(response => { return resolve(response) })
          .catch(error => { return interceptor(reject, error) })
      })
    },
    getLeadHistoryCountOfSalesperson(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/salesperson-list/count-lead-of-salesperson/${id}`)
          .then(response => { return resolve(response) })
          .catch(error => { return interceptor(reject, error) })
      })
    },
  },
}
