export default [
  {
    path: '/dashboard',
    name: 'dashboard',
    title: 'Dashboard',
    component: () => { return import('@/views/menus/dashboard/Main.vue') },
    meta: {
      tabTitle: 'Dashboard',
      pageTitle: 'Dashboard',
    },
  },
  {
    path: '/dashboard/analytics',
    name: 'dashboard-analytics',
    component: () => { return import('@/views/dashboard/analytics/Analytics.vue') },
  },
  {
    path: '/dashboard/ecommerce',
    name: 'dashboard-ecommerce',
    component: () => { return import('@/views/dashboard/ecommerce/Ecommerce.vue') },
  },
]
