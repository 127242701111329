import Vue from 'vue'
import Vuex from 'vuex'

// Modules
import ecommerceStoreModule from '@/views/apps/e-commerce/eCommerceStoreModule'
import userStoreModule from '@/views/menus/user/userStoreModule'
import formStoreModule from '@/views/menus/forms/formStoreModule'
import landingPageStoreModule from '@/views/menus/landing-page/landingPageStoreModule'
import leadStoreModule from '@/views/menus/leads/leadStoreModule'
import dashboardStoreModule from '@/views/menus/dashboard/dashboardStoreModule'
import app from './app'
import appConfig from './app-config'
import verticalMenu from './vertical-menu'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    /* E-Commerce */
    'app-ecommerce': ecommerceStoreModule,
    /* Users */
    'app-user': userStoreModule,
    /* Form */
    'menu-dashboard': dashboardStoreModule,
    /* Form */
    'menu-form': formStoreModule,
    /* Landing Page */
    'menu-landing-page': landingPageStoreModule,
    /* Lead */
    'menu-lead': leadStoreModule,
  },
  state: {
    currentUser: null,
    leadTabs: '',
    entriesPageDatatable: 1,
    leadStatusEntries: 0,
  },
  strict: process.env.DEV,
})
