import axios from '@axios'
import interceptor from '@/store/interceptor'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getSelf() {
      return new Promise((resolve, reject) => {
        axios
          .get('/auth/user')
          .then(response => { return resolve(response) })
          .catch(error => { return interceptor(reject, error) })
      })
    },
    getCompanyUsers() {
      return new Promise((resolve, reject) => {
        axios
          .get('/user/select2-company-user')
          .then(response => { return resolve(response) })
          .catch(error => { return interceptor(reject, error) })
      })
    },
    getRoles(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/roles/get-all', { params: queryParams })
          .then(response => { return resolve(response) })
          .catch(error => { return interceptor(reject, error) })
      })
    },
    getUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/users', { params: queryParams })
          .then(response => { return resolve(response) })
          .catch(error => { return interceptor(reject, error) })
      })
    },
    fetchUsers(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/apps/user/users', { params: queryParams })
          .then(response => { return resolve(response) })
          .catch(error => { return interceptor(reject, error) })
      })
    },
    getUser(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/users/${id}`)
          .then(response => { return resolve(response) })
          .catch(error => { return interceptor(reject, error) })
      })
    },
    addUser(ctx, userData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/users', userData)
          .then(response => { return resolve(response) })
          .catch(error => { return interceptor(reject, error) })
      })
    },
    updateUser(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/users/${payload.id}`, payload.data)
          .then(response => { return resolve(response) })
          .catch(error => { return interceptor(reject, error) })
      })
    },
    removeUser(ctx, { userId }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/users/${userId}`)
          .then(response => { return resolve(response) })
          .catch(error => { return interceptor(reject, error) })
      })
    },
  },
}
