import axios from '@axios'
import interceptor from '@/store/interceptor'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getLandingPages(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/landing-pages', { params: queryParams })
          .then(response => { return resolve(response) })
          .catch(error => { return interceptor(reject, error) })
      })
    },
    getLandingPage(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/landing-pages/${id}`)
          .then(response => { return resolve(response) })
          .catch(error => { return interceptor(reject, error) })
      })
    },
    storeVisitor(ctx, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/landing-pages/store-visitor', formData)
          .then(response => { return resolve(response) })
          .catch(error => { return reject(error) })
      })
    },
    getLandingPageFormByUuid(ctx, { uuid }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/landing-pages/show-form/by-uuid/${uuid}`)
          .then(response => { return resolve(response) })
          .catch(error => { return reject(error) })
      })
    },
    getLandingPageFormBySlug(ctx, { slug }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/landing-pages/show-form/by-slug/${slug}`)
          .then(response => { return resolve(response) })
          .catch(error => { return reject(error) })
      })
    },
    getLandingPageFormByUncountSlug(ctx, { uncountSlug }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/landing-pages/show-form/by-uncount-slug/${uncountSlug}`)
          .then(response => { return resolve(response) })
          .catch(error => { return reject(error) })
      })
    },
    getAssignedSalespersonByUuid(ctx, { uuid }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/landing-pages/get-assigned-salesperson/by-uuid/${uuid}`)
          .then(response => { return resolve(response) })
          .catch(error => { return reject(error) })
      })
    },
    getAssignedSalespersonBySlug(ctx, { slug }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/landing-pages/get-assigned-salesperson/by-slug/${slug}`)
          .then(response => { return resolve(response) })
          .catch(error => { return reject(error) })
      })
    },
    getAssignedSalespersonByUncountSlug(ctx, { uncountSlug }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/landing-pages/get-assigned-salesperson/by-uncount-slug/${uncountSlug}`)
          .then(response => { return resolve(response) })
          .catch(error => { return reject(error) })
      })
    },
    addLandingPage(ctx, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/landing-pages', formData)
          .then(response => { return resolve(response) })
          .catch(error => { return interceptor(reject, error) })
      })
    },
    updateLandingPage(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/landing-pages/${payload.get('id')}`, payload)
          .then(response => { return resolve(response) })
          .catch(error => { return interceptor(reject, error) })
      })
    },
    removeLandingPage(ctx, { formId }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/landing-pages/${formId}`)
          .then(response => { return resolve(response) })
          .catch(error => { return interceptor(reject, error) })
      })
    },
  },
}
