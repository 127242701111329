import axios from '@axios'
import interceptor from '@/store/interceptor'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getForms(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/forms', { params: queryParams })
          .then(response => { return resolve(response) })
          .catch(error => { return interceptor(reject, error) })
      })
    },
    getAllForms(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/forms/get-all', { params: queryParams })
          .then(response => { return resolve(response) })
          .catch(error => { return interceptor(reject, error) })
      })
    },
    getForm(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/forms/${id}`)
          .then(response => { return resolve(response) })
          .catch(error => { return interceptor(reject, error) })
      })
    },
    getFormById(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/forms/by-id/${id}`)
          .then(response => { return resolve(response) })
          .catch(error => { return reject(error) })
      })
    },
    addForm(ctx, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/forms', formData)
          .then(response => { return resolve(response) })
          .catch(error => { return interceptor(reject, error) })
      })
    },
    updateForm(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/forms/${payload.id}`, payload.data)
          .then(response => { return resolve(response) })
          .catch(error => { return interceptor(reject, error) })
      })
    },
    removeForm(ctx, { formId }) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/forms/${formId}`)
          .then(response => { return resolve(response) })
          .catch(error => { return interceptor(reject, error) })
      })
    },
    inputForm(ctx, formData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/form-inputs', formData)
          .then(response => { return resolve(response) })
          .catch(error => { return reject(error) })
      })
    },
  },
}
