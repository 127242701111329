export default [
  // *--------- USER --------------*
  {
    path: '/users',
    name: 'users',
    title: 'Users - List',
    component: () => { return import('@/views/menus/user/users-list/UsersList.vue') },
    meta: {
      tabTitle: 'Users - List',
    },
  },
  {
    path: '/users/edit/:id',
    name: 'user-edit',
    title: 'User - Edit',
    component: () => { return import('@/views/menus/user/users-edit/UsersEdit.vue') },
    meta: {
      tabTitle: 'User - Edit',
    },
  },

  // *--------- Form Manager --------------*
  {
    path: '/form-managers/display/:id',
    name: 'form-managers-display',
    title: 'Form - Display',
    component: () => { return import('@/views/menus/forms/form-display/FormDisplay.vue') },
    meta: {
      parent: 'form-managers',
      tabTitle: 'Form - Display',
      layout: 'full',
      active: true,
      // resource: 'Auth',
      // action: 'read',
    },
  },
  {
    path: '/form-managers/create',
    name: 'form-managers-create',
    title: 'Form - Create',
    component: () => { return import('@/views/menus/forms/form-create/FormRepeater.vue') },
    meta: {
      parent: 'form-managers',
      tabTitle: 'Form - Create',
      pageTitle: 'Form',
      breadcrumb: [
        {
          text: 'List',
          to: { name: 'form-managers-list' },
        },
        {
          text: 'Create',
          active: true,
        },
      ],
    },
  },
  {
    path: '/form-managers/edit/:id',
    name: 'form-managers-edit',
    title: 'Form - Edit',
    component: () => { return import('@/views/menus/forms/form-create/FormRepeater.vue') },
    meta: {
      parent: 'form-managers',
      tabTitle: 'Form - Edit',
      pageTitle: 'Form',
      breadcrumb: [
        {
          text: 'List',
          to: { name: 'form-managers-list' },
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/form-managers',
    name: 'form-managers-list',
    title: 'Form - List',
    component: () => { return import('@/views/menus/forms/forms-list/FormsList.vue') },
    meta: {
      parent: 'form-managers',
      tabTitle: 'Form - List',
      pageTitle: 'Form',
      breadcrumb: [
        {
          text: 'List',
          to: { name: 'form-managers-list' },
          active: true,
        },
      ],
    },
  },

  // *--------- Landing Pages --------------*
  {
    path: '/landing-pages/d/:uuid',
    name: 'landing-pages-display-by-uuid',
    title: 'Landing Page - Display',
    component: () => { return import('@/views/menus/landing-page/display/FormDisplay.vue') },
    meta: {
      parent: 'landing-pages',
      tabTitle: 'Landing Page - Display',
      layout: 'full',
      active: true,
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/lp/:slug',
    name: 'landing-pages-display-by-slug',
    title: 'Landing Page - Display',
    component: () => { return import('@/views/menus/landing-page/display/FormDisplay.vue') },
    meta: {
      parent: 'landing-pages',
      tabTitle: 'Landing Page - Display',
      layout: 'full',
      active: true,
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/landing-pages/d/:uuid/thank-you',
    name: 'landing-pages-thank-you',
    title: 'Landing Page - Thank You Page',
    component: () => { return import('@/views/menus/landing-page/display/ThankYou.vue') },
    meta: {
      parent: 'landing-pages',
      tabTitle: 'Landing Page - Thank You Page',
      layout: 'full',
      active: true,
      resource: 'Auth',
      action: 'read',
    },
  },
  {
    path: '/landing-pages/create',
    name: 'landing-pages-create',
    title: 'Landing Page - Create',
    component: () => { return import('@/views/menus/landing-page/create/Form.vue') },
    meta: {
      parent: 'landing-pages',
      tabTitle: 'Landing Page - Create',
      pageTitle: 'Landing Page',
      breadcrumb: [
        {
          text: 'List',
          to: { name: 'landing-pages-list' },
        },
        {
          text: 'Create',
          active: true,
        },
      ],
    },
  },
  {
    path: '/landing-pages/edit/:id',
    name: 'landing-pages-edit',
    title: 'Landing Page - Edit',
    component: () => { return import('@/views/menus/landing-page/create/Form.vue') },
    meta: {
      parent: 'landing-pages',
      tabTitle: 'Landing Page - Edit',
      pageTitle: 'Landing Page',
      breadcrumb: [
        {
          text: 'List',
          to: { name: 'landing-pages-list' },
        },
        {
          text: 'Edit',
          active: true,
        },
      ],
    },
  },
  {
    path: '/landing-pages',
    name: 'landing-pages-list',
    title: 'Landing Page - List',
    component: () => { return import('@/views/menus/landing-page/list/Datalist.vue') },
    meta: {
      parent: 'landing-pages',
      tabTitle: 'Landing Page - List',
      pageTitle: 'Landing Page',
      breadcrumb: [
        {
          text: 'List',
          to: { name: 'landing-pages-list' },
          active: true,
        },
      ],
    },
  },

  // *--------- Leads --------------*
  {
    path: '/leads',
    name: 'leads-list',
    title: 'Leads - List',
    component: () => { return import('@/views/menus/leads/list/Datalist.vue') },
    meta: {
      parent: 'leads',
      tabTitle: 'Leads - List',
      pageTitle: 'Leads',
      breadcrumb: [
        {
          text: 'List',
          active: true,
        },
      ],
    },
  },
  {
    path: '/leads/:uuid',
    name: 'leads-landing-page',
    title: 'Leads - Detail',
    component: () => { return import('@/views/menus/leads/details/LPDetail.vue') },
    meta: {
      parent: 'leads',
      tabTitle: 'Leads - Detail',
      pageTitle: 'Leads',
      breadcrumb: [
        {
          text: 'List',
          to: { name: 'leads-list' },
        },
        {
          text: 'Detail',
          unique: 'uuid',
          active: true,
        },
      ],
    },
  },
  {
    path: '/leads/:uuid/detail/:id',
    name: 'leads-detail',
    title: 'Leads - Detail',
    component: () => { return import('@/views/menus/leads/details/components/LeadDetail.vue') },
    meta: {
      parent: 'leads',
      tabTitle: 'Leads - Detail',
      pageTitle: 'Leads',
      breadcrumb: [
        {
          text: 'List',
          to: { name: 'leads-list' },
        },
        {
          text: 'Detail',
          unique: 'uuid',
          routeName: 'leads-landing-page',
        },
        {
          text: 'Entries',
          unique: 'id',
          active: true,
        },
      ],
    },
  },
  {
    path: '/user-setting',
    name: 'user-setting',
    title: 'Settings',
    component: () => { return import('@/views/menus/user/AccountSetting.vue') },
    meta: {
      parent: 'user-setting',
      tabTitle: 'User Settings',
      pageTitle: 'Settings',
      breadcrumb: [
        {
          text: 'User Settings',
          active: true,
        },
      ],
    },
  },
  {
    path: '/:company/:slug',
    name: 'landing-pages-display-by-company-slug',
    title: 'Landing Page - Display',
    component: () => { return import('@/views/menus/landing-page/display/FormDisplay.vue') },
    meta: {
      parent: 'landing-pages',
      tabTitle: 'Landing Page - Display',
      layout: 'full',
      active: true,
      resource: 'Auth',
      action: 'read',
    },
  },
  // Landing page stucel
  {
    path: '/stucel-digital-marketing-agency',
    name: 'stucel-digital-marketing-agency',
    title: 'Stucel Digital Marketing Agency',
    component: () => { return import('@/views/menus/landing-page/Stucel/index.vue') },
    meta: {
      tabTitle: 'Stucel Digital Marketing Agency',
      layout: 'full',
      active: true,
      resource: 'Auth',
      action: 'read',
    },
  },
]
